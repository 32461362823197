































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Seminar } from "@/core/models";

@Component
export default class SeminarList extends Vue {
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Getter("profile/id") userId!: string;
}
